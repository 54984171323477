import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dispositivo } from '../../model/dispositivos/dispostivo';

@Injectable({
  providedIn: 'root'
})
export class DispositivoService {

  private url = environment.url + '/dispositivo';
  constructor(
    private http: HttpClient
  ) {

  }


  public salvarDispositivo(formData: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-dispositivo`, formData);
  }

  public editarDispositivo(formData: any): Observable<any> {
    return this.http.put<any>(`${this.url}/eng-dispositivo`, formData);
  }

  public consultarTodasDispositivo(codigo: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-dispositivo/${codigo}`);
  }

  public consultarTodasPaginada(pagina: number, quantidadePorPagina: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-dispositivo/${pagina}/${quantidadePorPagina}`);
  }

  public consultarPorCdDispostivo(cdDispositivo: number): Observable<any> {
    return this.http.get<any>(
      `${this.url}/eng-dispositivo/${cdDispositivo}`
    );
  }

  public consultarDispositivoPorNome(nomeDispositivo: string): Observable<any> {
    return this.http.get<any>(`${this.url}/eng-dispositivo/nome/${nomeDispositivo}`);
  }

  public consultarPorIdProcessoIdProduto(idProcesso: number, idDispositivo: number): Observable<Dispositivo[]> {
    return this.http.get<Dispositivo[]>(
      `${this.url}/eng-dispositivo/${idProcesso}/${idDispositivo}`
    );
  }

  public adicionarMaisImagens(formData: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-dispositivo/imagem`, formData);
  }
  // public consultarImagensPorNome(nomeArquivo: string): Observable<Blob> {
  //   return this.http.get(`${this.url}/eng-dispositivo/download/${nomeArquivo}`, { responseType: 'blob' });
  // }


  public deletarDispositivoImagem(id: number): Observable<any> {
    return this.http.delete(`${this.url}/eng-dispositivo/excluir-imagem/${id}`);
  }
  public deletarDispositivo(id: number): Observable<any> {
    return this.http.delete(`${this.url}/eng-dispositivo/${id}`);
  }

}
