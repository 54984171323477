import { Imagens } from "../imagens";

export class Dispositivo {
    id: number = 0;
    nome: string = '';
    qtdeDisponivel: number = 0;
    observacao:string = ''
    imagens:Imagens[] = [];
  }
  

  